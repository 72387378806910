import styled ,{ keyframes } from 'styled-components';
import { Helmet } from 'react-helmet';

import BackgroundImageSrc from './assets/background-final.png';
import BabyImageSrc from './assets/just-baby-meme.png';
import NoteImageSrc from './assets/note.png';

 import AudioPlayer from './components/AudioPlayer';
// import Winamp from "./components/Winamp"
import Links from './components/Links';


const noteFloatKeyframes = keyframes`
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(5px, -10px);
  }
  50% {
    transform: translate(-5px, 10px);
  }
  75% {
    transform: translate(10px, 5px);
  }
  100% {
    transform: translate(0, 0);
  }
`;


const AppWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: lightblue;
  position: relative;

  .background-image {
    width: 100vw;
    height: 100vh;
    position: absolute;
  }

  .baby-image {
    position: absolute;
    bottom: 10%;
    left: 5%;
    width: 200px;
    opacity: 0.6;
    z-index: 0;

    animation: ${noteFloatKeyframes} 14s infinite ease-in-out;

    @media (max-width: 768px) {
      width: 175px;
    }

    @media (max-width: 500px) {
      width: 150px;
    }

    @media (max-width: 400px) {
      bottom: 10%;
      left: 50%;
      width: 140px;
      transform: translate(-50%, -20%);
    }
  }
  .note-image {
    position: absolute;
    top: 0%;
    right: 5%;
    width: 140px;
    opacity:0.8;

    animation: ${noteFloatKeyframes} 14s infinite ease-in-out;

    @media (max-width: 768px) {
      width: 120px;
      right: 2%;
    }

    @media (max-width: 400px) {
      width: 100px;
      left: 55%;
      transform: translate(-50%, -30%);
    }
  }

  .text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    max-height: 100%;
    transform: translate(0, -5%);

    h1 {
      color: #240d22;
      font-family: 'Nirvana';
      font-size: 400px;
      font-weight: 400;
      opacity: 0.9;
      padding: 0;
      margin: 0;
    

      @media (max-width: 768px) {
        transform: translate(0, -20%);
        font-size: 250px;
      }

      @media (max-width: 400px) {
        font-size: 200px;
        transform: translate(0, -30%);
      }
    }

    h3 {
      color: white;
      opacity: 0.75;
      text-align: center;
      max-width: 50%;
      padding: 0;
      margin: 0;
      font-size: 20px;
      line-height: 1.3;
      opacity: 0.75;
      z-index: 5000;
      text-shadow: 0px 5px 9px rgba(0, 0, 0, 0.25);

      @media (max-width: 768px) {
        font-size: 18px;
        max-width: 70%;
      }

      @media (max-width: 500px) {
        font-size: 16px;
        transform: translate(0, -10%);
      }

      @media (max-width: 400px) {
        font-size: 14px;
        max-width: 80%;
      }
    }
  }
`;

function App() {
  return (
    <AppWrapper>
      <Helmet>
        <title>$NVM</title>
        <meta
          name="description"
          content="Meet NVM, the Solana meme token that channels the essence of '90s nostalgia. NVM merges the grungy, rebellious spirit of the '90s with the cutting-edge world of cryptocurrency. It's more than just a token; it's a tribute to a legendary era."
        />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="$NVM" />
        <meta
          property="og:description"
          content="Meet NVM, the Solana meme token that channels the essence of '90s nostalgia. NVM merges the grungy, rebellious spirit of the '90s with the cutting-edge world of cryptocurrency. It's more than just a token; it's a tribute to a legendary era."
        />
        <meta property="og:image" content="/site-preview.png" />
        <meta property="og:url" content="https://nvmsol.com/" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="/site-preview.png" />
        <meta name="twitter:title" content="$NVM" />
        <meta
          name="twitter:description"
          content="Meet NVM, the Solana meme token that channels the essence of '90s nostalgia. NVM merges the grungy, rebellious spirit of the '90s with the cutting-edge world of cryptocurrency. It's more than just a token; it's a tribute to a legendary era."
        />
        {/* <meta name="twitter:image" content="https://example.com/image.jpg" /> */}
      </Helmet>
      <img
        className="background-image"
        alt="background"
        src={BackgroundImageSrc}
      ></img>

      <div className="text-container">
        <h1>NVM</h1>

         <AudioPlayer /> 
        {/* <Winamp/> */}

        <h3>
          Meet "NVM," the Solana meme token that channels the essence of '90s
          nostalgia. NVM merges the grungy, rebellious spirit of the '90s with
          the cutting-edge world of cryptocurrency. It's more than just a token;
          it's a tribute to a legendary era.
        </h3>
      </div>

      <Links />

      <img className="baby-image" alt="baby" src={BabyImageSrc}></img>
      <img className="note-image" alt="note" src={NoteImageSrc}></img>

      {/* //subpop */}
    </AppWrapper>
  );
}

export default App;
