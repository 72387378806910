// FileName: data.js

import { v4 as uuidv4 } from 'uuid';

import Track1Src from '../../assets/tracks/track1.mp3';
import Track2Src from '../../assets/tracks/track2.mp3';
import Track3Src from '../../assets/tracks/track3.mp3';
import Track4Src from '../../assets/tracks/track4.mp3';
import Track5Src from '../../assets/tracks/track5.mp3';
import Track6Src from '../../assets/tracks/track6.mp3';
import Track7Src from '../../assets/tracks/track7.mp3';
import Track8Src from '../../assets/tracks/track8.mp3';
import Track9Src from '../../assets/tracks/track9.mp3';
import Track10Src from '../../assets/tracks/track10.mp3';
import Track11Src from '../../assets/tracks/track11.mp3';
import Track12Src from '../../assets/tracks/track12.mp3';

function chillHop() {
  return [
    {
      name: 'Smells like degen spirit',
      cover: '',
      //   audio:
      //     'https://media.geeksforgeeks.org/wp-content/uploads/20231004185212/Jawan-Prevue-Theme.mp3',
      audio: Track1Src,
      color: ['#205950', '#2ab3bf'],
      id: uuidv4(),
      active: true,
    },
    {
      name: 'In BOOM!',
      cover: '',
      audio: Track2Src,
      color: ['#EF8EA9', '#ab417f'],
      id: uuidv4(),
      active: false,
    },
    {
      name: 'Degen as you are',
      cover: '',
      audio: Track3Src,
      color: ['#CD607D', '#c94043'],
      id: uuidv4(),
      active: false,
    },
    {
      name: 'Greed',
      cover: '',
      audio: Track4Src,
      color: ['#EF8EA9', '#ab417f'],
      id: uuidv4(),
      active: false,
    },
    {
      name: 'Solithium',
      cover: '',
      audio: Track5Src,
      color: ['#CD607D', '#c94043'],
      id: uuidv4(),
      active: false,
    },
    {
      name: 'Solly',
      cover: '',
      audio: Track6Src,
      color: ['#205950', '#2ab3bf'],
      id: uuidv4(),
      active: false,
    },
    {
      name: 'Territorial Shillings',
      cover: '',
      audio: Track7Src,
      color: ['#205950', '#2ab3bf'],
      id: uuidv4(),
      active: false,
    },
    {
      name: 'Brain You',
      cover: '',
      audio: Track8Src,
      color: ['#205950', '#2ab3bf'],
      id: uuidv4(),
      active: false,
    },
    {
      name: 'Lounge Hack',
      cover: '',
      audio: Track9Src,
      color: ['#205950', '#2ab3bf'],
      id: uuidv4(),
      active: false,
    },
    {
      name: 'Stake Away',
      cover: '',
      audio: Track10Src,
      color: ['#205950', '#2ab3bf'],
      id: uuidv4(),
      active: false,
    },
    {
      name: 'On a chain',
      cover: '',
      audio: Track11Src,
      color: ['#205950', '#2ab3bf'],
      id: uuidv4(),
      active: false,
    },
    {
      name: 'Solana on the way',
      cover: '',
      audio: Track12Src,
      color: ['#205950', '#2ab3bf'],
      id: uuidv4(),
      active: false,
    },
  ];
}

export default chillHop;
