import styled from 'styled-components';

const StyledLinks = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 4%;
  width: 50%;
  padding: 0;
  margin: 0;

  @media (max-width: 500px) {
    bottom: 3%;
  }

  a {
    color: #240d22;
    font-family: 'Nirvana';
    font-size: 28px;
    color: white;
    cursor: pointer;
    opacity: 0.75;
    text-shadow: 0px 5px 9px rgba(0, 0, 0, 1);

    @media (max-width: 500px) {
      font-size: 24px;
    }
  }

  .link-disabled {
    opacity: 0.3;
    cursor: auto;
  }
`;

const Links = () => {
  return (
    <StyledLinks>
      <a href="https://t.me/+g_ClQUJW1k45MDU8" target="_blank" rel="noreferrer">
        TG
      </a>
      <a href="https://x.com/nvm_on_sol" target="_blank" rel="noreferrer">
        X
      </a>
      <a
        className="link-disabled"
        href="https://google.com"
        target="_blank"
        rel="noreferrer"
      >
        PF
      </a>
    </StyledLinks>
  );
};

export default Links;
